import React from "react";
import { BrowserRouter } from "react-router-dom";

import { ApolloProvider } from "Components/Utils/ApolloProvider";
import { ReactQueryProvider } from "Components/Utils/ReactQueryProvider";
import { FirebaseProvider } from "Components/Utils/FirebaseProvider";
import { ReduxProvider } from "Redux/ReduxProvider";
import { HyreCarApiProvider } from "Components/Utils/HyreCarApiProvider";
import { Root } from "Root";
import ScrollToTop from "Components/Utils/ScrollToTop";
import { AuthProvider } from "Login/Auth";
import { ThemeProvider } from "Styles/ThemeProvider";
import { withLegacyMetrics } from "Analytics/index";
import { PageTracker } from "Analytics/PageTracker";
import { AnalyticsProvider } from "Analytics/AnalyticsContext";
import { DriverProfileProvider } from "Components/Utils/DriverProfileProvider";
import { AlgoliaProvider } from "Components/Utils/AlgoliaProvider";
import { GigConnectProvider } from "Components/Utils/GigConnectProvider";
import { SelectServicesFormProvider } from "Components/Utils/SelectServicesFormProvider";
import DDRouteTracker from "./DDRouteTracker";
import "Driver/styles/App.css";

export default withLegacyMetrics(() => {
  return (
    <BrowserRouter>
      <DDRouteTracker />
      <ApolloProvider>
        <ReactQueryProvider>
          <AlgoliaProvider>
            <FirebaseProvider>
              <ReduxProvider>
                <HyreCarApiProvider>
                  <AnalyticsProvider>
                    <AuthProvider>
                      <ThemeProvider>
                        <DriverProfileProvider>
                          <GigConnectProvider>
                            <ScrollToTop>
                              <SelectServicesFormProvider>
                                <Root />
                              </SelectServicesFormProvider>
                              <PageTracker />
                            </ScrollToTop>
                          </GigConnectProvider>
                        </DriverProfileProvider>
                      </ThemeProvider>
                    </AuthProvider>
                  </AnalyticsProvider>
                </HyreCarApiProvider>
              </ReduxProvider>
            </FirebaseProvider>
          </AlgoliaProvider>
        </ReactQueryProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
});
