import { datadogRum } from "@datadog/browser-rum";
import config from "Config/config";

datadogRum.init({
  applicationId: config.datadog.applicationId,
  clientToken: config.datadog.clientToken,
  site: config.datadog.site,
  service: config.clientName,
  env: config.stage,
  version: config.REACT_APP_VERSION,
  // we want to track 50% of the users
  sessionSampleRate: config.stage === "staging" ? 100 : 50,
  // from that 50% we want to record 100% of them
  sessionReplaySampleRate: 100,
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true, // heatmaps
  trackInteractions: true, // Enables user interaction tracking
  defaultPrivacyLevel: "mask-user-input" // Masks sensitive data
});
