import "./datadog";
import React from "react";
import ReactDOM from "react-dom";

import App from "App";
import { initBranch } from "Utils/BranchHelper";

initBranch();

ReactDOM.render(<App />, document.getElementById("root"));
