import React, { useState, useReducer, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import PropTypes from "prop-types";

import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { analytics } from "Analytics/index";
import { FETCH_ARGYLE_TOKEN } from "Queries/Argyle/ArgyleQueries";

export const GigConnectContext = React.createContext();

const { action, category, label } = AnalyticsEvents;

const trackEvent = (event, action, category, property) => {
  return analytics.track(event, {
    action,
    category,
    context: "",
    label: label.argyle,
    property: JSON.stringify(property),
    value: ""
  });
};

const getArgyleInstance = ({ token }) => {
  if (!token) return;
  return window.Argyle.create({
    flowId: "0YPIKAUB",
    userToken: token,
    sandbox: process.env.REACT_APP_STAGE === "staging" ? true : false,
    onAccountCreated: ({ account_id, link_item_id, userId: user_id }) =>
      trackEvent(
        label.driver.argyle.accountConnected,
        action.webConversion,
        category.userInteraction,
        {
          account_id,
          user_id,
          link_item_id
        }
      ),
    onAccountConnected: ({ account_id, userId: user_id, link_item_id }) =>
      trackEvent(
        label.driver.argyle.accountConnected,
        action.webConversion,
        category.userInteraction,
        {
          account_id,
          user_id,
          link_item_id
        }
      ),
    onAccountUpdated: ({ account_id, userId: user_id, link_item_id }) =>
      trackEvent(
        label.driver.argyle.accountUpdated,
        action.formSubmitted,
        category.userInteraction,
        {
          account_id,
          user_id,
          link_item_id
        }
      ),
    onAccountRemoved: ({ account_id, userId: user_id, link_item_id }) =>
      trackEvent(
        label.driver.argyle.accountRemoved,
        action.formSubmitted,
        category.userInteraction,
        {
          account_id,
          user_id,
          link_item_id
        }
      ),
    onPayDistributionSuccess: ({ account_id, userId: user_id, link_item_id }) =>
      trackEvent(
        label.driver.argyle.payDistribution,
        action.formSubmitted,
        category.userInteraction,
        {
          account_id,
          user_id,
          link_item_id
        }
      ),
    onPayDistributionError: ({ account_id, userId: user_id, link_item_id }) =>
      trackEvent(
        label.driver.argyle.payDistribution,
        action.requestFailed,
        category.deviceNetworking,
        {
          account_id,
          user_id,
          link_item_id
        }
      ),
    onError: ({ error }) =>
      trackEvent(
        label.driver.argyle.error,
        action.requestFailed,
        category.deviceNetworking,
        {
          error
        }
      ),
    onUserCreated: ({ userId: user_id }) => {
      trackEvent(
        label.driver.argyle.userCreated,
        action.webConversion,
        category.userInteraction,
        {
          user_id
        }
      );
    },
    onClose: () =>
      trackEvent(
        label.driver.argyle.close,
        action.buttonClicked,
        category.userInteraction,
        {}
      ),
    onExitIntroClicked: () =>
      trackEvent(
        label.driver.argyle.cancel,
        action.buttonClicked,
        category.userInteraction,
        {}
      ),
    onAccountError: ({ account_id, userId: user_id, link_item_id }) =>
      trackEvent(
        label.driver.argyle.accountError,
        action.requestFailed,
        category.deviceNetworking,
        { account_id, user_id, link_item_id }
      ),
    onUIEvent: ({ name, properties }) =>
      trackEvent(
        label.driver.argyle.event,
        action.buttonClicked,
        category.userInteraction,
        {
          ...properties,
          name
        }
      )
  });
};

export const GigConnectActionTypesEnum = {
  SetLink: "SetLink"
};

const reducer = (state, action) => {
  switch (action.type) {
    case GigConnectActionTypesEnum.SetLink:
      return {
        ...state,
        link: getArgyleInstance({ ...action.values })
      };
  }
};

export const GigConnectProvider = ({ children }) => {
  const [state, dispatchGigConnectAction] = useReducer(reducer, {
    link: undefined
  });

  const [fetchArgyleToken, { loading }] = useLazyQuery(FETCH_ARGYLE_TOKEN, {
    fetchPolicy: "cache-and-network"
  });

  const [componentOpening, setComponentOpening] = useState(null);

  useEffect(() => {
    if (state?.link) state?.link.open();
  }, [state?.link]);

  const openLink = async () => {
    const { data } = await fetchArgyleToken();

    const token = data?.fetchArgyleToken?.token;

    if (!token) {
      console.error("Error getting argyle token.");
      return;
    }

    dispatchGigConnectAction({
      values: {
        token
      },
      type: GigConnectActionTypesEnum.SetLink
    });
  };

  return (
    <GigConnectContext.Provider
      value={{
        openLink,
        loading,
        setComponentOpening,
        componentOpening
      }}
    >
      {children}
    </GigConnectContext.Provider>
  );
};

GigConnectProvider.propTypes = {
  children: PropTypes.node
};
